import React, { useEffect } from 'react';

import logo from '../../assets/img/logo.svg';
import imgGenderMale from '../../assets/img/male.svg';
import imgGenderFemale from '../../assets/img/female.svg';
import { useNavigate } from 'react-router-dom';
import MainFormLoading from './MainFormLoading';
import { useApi } from '../../providers/ApiProvider';
import InfoModel from '../../models/InfoModel';
import Results from './Results';

export default function MainForm(props) {
  const MAX_HEIGHT = 40;
  const MIN_HEIGHT = 14;
  const MAX_WEIGHT = 34;
  const MIN_WEIGHT = 1;

  //make today as YYYY-MM-DD
  const tmpToday = new Date();
  const tmpTodayFormatted = tmpToday.toISOString().split('T')[0];

  const navigate = useNavigate();
  const apiContext = useApi();
  const infoModel = new InfoModel(apiContext.api);
  const [gender, setGender] = React.useState('male');
  const [birthday, setBirthday] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [weight, setWeight] = React.useState('');
  const [measureDate, setMeasureDate] = React.useState(tmpTodayFormatted);
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const brands = ['H&M', 'Gap', "Carter's", 'Ralph Lauren', 'Zara'];
  const [results, setResults] = React.useState({});
  const [measurementId, setMeasurementId] = React.useState(null);

  useEffect(() => {
    document.title = 'Get sizes | Grow with me!';
    const originData = window.localStorage.getItem('result') ? JSON.parse(window.localStorage.getItem('result')) : {};
    const originMeasurementId = window.localStorage.getItem('measurementId')
      ? window.localStorage.getItem('measurementId')
      : null;

    //check if location has /results but results are empty
    if (window.location.pathname === '/results' && !Object.keys(originData).length) {
      navigate('/get');
      return;
    }

    if (!originMeasurementId) {
      const id = createUniqueId();
      setMeasurementId(id);
      window.localStorage.setItem('measurementId', id);
    } else {
      setMeasurementId(originMeasurementId);
    }

    setResults(originData);
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem('result') && Object.keys(results).length) {
      saveResults();
    }
  }, [results]);

  const saveResults = () => {
    window.localStorage.setItem('result', JSON.stringify(results));
    const data = {
      id: measurementId,
      gender: gender,
      birthday: birthday,
      height: height,
      weight: weight,
      measureDate: measureDate,
      sizes: results,
    };

    infoModel.saveResults(data);
  };

  const createUniqueId = () => {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  const onBackClick = e => {
    e.preventDefault();
    window.localStorage.clear();

    if (Object.keys(results).length) {
      const id = createUniqueId();
      setResults({});
      setMeasurementId(id);
      window.localStorage.setItem('measurementId', id);
      navigate('/get');
    } else {
      navigate('/');
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    let errorsHeight = validateHeight(height);
    let errorsWidth = validateWidth(weight);
    let errorsBirthday = validateBirthday(birthday);
    let errorsMeasureDate = validateMeasureDate(measureDate);
    //check if measure date is after birthday
    if (birthday && measureDate && measureDate < birthday) {
      errorsMeasureDate.push("Measure date can't be before birthday.");
    }
    //check if distance is more than 2 years
    if (birthday && measureDate) {
      const birthdayDate = new Date(birthday);
      const measureDateDate = new Date(measureDate);
      const diffTime = Math.abs(measureDateDate - birthdayDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 365 * 2) {
        errorsMeasureDate.push("Measure date can't be more than 2 years after birthday.");
      }
    }

    if (errorsHeight.length || errorsWidth.length || errorsBirthday.length || errorsMeasureDate.length) {
      setErrors({
        height: errorsHeight.join(' '),
        weight: errorsWidth.join(' '),
        birthday: errorsBirthday.join(' '),
        measure: errorsMeasureDate.join(' '),
      });
      return;
    } else {
      setErrors({});
    }

    setIsLoading(true);

    let sizes = {};
    let calculations = [];

    brands.forEach(brand => {
      const data = {
        gender: gender,
        brand: brand,
        type: 'type',
        height: height,
        weight: weight,
        birthday: birthday,
        measureDate: measureDate,
      };

      let promise = infoModel.calculate(data);
      calculations.push(promise);
    });

    Promise.all(calculations).then(responses => {
      let failedBrands = {};
      responses.forEach(response => {
        if (response) {
          if (response.error) {
            failedBrands[response.brand] = response.minimum ? 'minimum' : response.maximum ? 'maximum' : false;
            return;
          }

          if (!failedBrands.hasOwnProperty(response.brand) || failedBrands[response.brand] === 'minimum') {
            //add response.months_to_next months to today
            let nextDate = new Date();
            nextDate.setMonth(nextDate.getMonth() + response.months_to_next);
            let daysToNext = Math.floor((nextDate - new Date()) / (1000 * 60 * 60 * 24));

            if (sizes[response.brand]) {
              if (sizes[response.brand].index < response.index) {
                sizes[response.brand] = {
                  current: response.current,
                  next: response.next,
                  index: response.index,
                  date: formatNextDate(nextDate),
                  day: formatNextDays(daysToNext),
                };
              }
            } else {
              sizes[response.brand] = {
                current: response.current,
                next: response.next,
                index: response.index,
                date: formatNextDate(nextDate),
                day: formatNextDays(daysToNext),
              };
            }
          }
        }
      });

      Object.keys(failedBrands).forEach(brand => {
        if (failedBrands[brand] !== 'minimum') {
          delete sizes[brand];
        }
      });

      setTimeout(() => {
        setResults(sizes);
        setIsLoading(false);
        navigate('/results');
      }, 200);
    });
  };

  const validateHeight = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Height is required.');
      return errorsList;
    }

    if (value > MAX_HEIGHT) {
      errorsList.push('Value too high.');
    } else if (value < MIN_HEIGHT) {
      errorsList.push('Value too low.');
    }

    return errorsList;
  };
  const onHeightChange = e => {
    let value = e.target.value;
    value = value.replace(/[^0-9,.]/g, '');
    let errors = validateHeight(value);
    if (errors.length) {
      setErrors({
        ...errors,
        height: errors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        height: '',
      });
    }

    setHeight(value);
  };

  const validateWidth = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Weight is required.');
      return errorsList;
    }

    if (value > MAX_WEIGHT) {
      errorsList.push('Value too high.');
    } else if (value < MIN_WEIGHT) {
      errorsList.push('Value too low.');
    }

    return errorsList;
  };
  const onWeightChange = e => {
    let value = e.target.value;
    value = value.replace(/[^0-9,.]/g, '');
    let errors = validateWidth(value);
    if (errors.length) {
      setErrors({
        ...errors,
        weight: errors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        weight: '',
      });
    }

    setWeight(value);
  };

  const validateBirthday = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Birthday is required.');
    }

    //check if date is not in future
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0];
    if (value > todayFormatted) {
      errorsList.push('Future date is not valid.');
    }

    //check if birthday more than 18 months ago
    const birthdayDate = new Date(value);
    const diffTime = Math.abs(today - birthdayDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 365 * 1.5) {
      errorsList.push('Only 24 months of forecasting.');
    }

    return errorsList;
  };
  const onBirthdayChange = e => {
    let formattedDate = null;
    const date = new Date(e.target.value);
    try {
      const date = new Date(e.target.value);
      formattedDate = date.toISOString().split('T')[0];
    } catch (error) {
      formattedDate = null;
      setErrors({
        ...errors,
        birthday: 'Birthday format is invalid.',
      });
      return;
    }
    let curErrors = validateBirthday(formattedDate);
    if (curErrors.length) {
      setErrors({
        ...errors,
        birthday: curErrors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        birthday: '',
      });
    }

    setBirthday(formattedDate);
  };

  const validateMeasureDate = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Measure date is required.');
    }

    //check if date is not in future
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0];
    if (value > todayFormatted) {
      errorsList.push('Future date is not valid.');
    }

    return errorsList;
  };
  const onMeasureDateChange = e => {
    let formattedDate = null;
    try {
      const date = new Date(e.target.value);
      formattedDate = date.toISOString().split('T')[0];
    } catch (error) {
      formattedDate = null;
      setErrors({
        ...errors,
        measure: 'Measure date format is invalid.',
      });
      return;
    }
    let curErrors = validateMeasureDate(formattedDate);
    if (curErrors.length) {
      setErrors({
        ...errors,
        measure: curErrors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        measure: '',
      });
    }

    setMeasureDate(formattedDate);
  };

  const formatNextDays = days => {
    if (days < 0) {
      return '';
    } else {
      return days + ' days';
    }
  };

  const formatNextDate = nextDate => {
    if (!nextDate) {
      return '';
    }

    //format nextDate as Nov 25
    let month = nextDate.toLocaleString('default', { month: 'short' }),
      day = nextDate.getDate(),
      year = nextDate.getFullYear();

    return `${month} ${day}`;
  };

  const getNextDays = (nextSize, currentSize) => {
    const nextDateRaw = getNextDateRaw(nextSize, currentSize);
    if (!nextDateRaw) {
      return '';
    }

    let curDate = new Date(),
      curInDays = Math.floor((nextDateRaw - curDate) / (1000 * 60 * 60 * 24)),
      curInMonths = Math.ceil(curInDays / 30);

    if (curInMonths < 0) {
      return '';
    }

    return curInDays + ' days';
  };

  const getNextDate = (nextSize, currentSize) => {
    const nextDateRaw = getNextDateRaw(nextSize, currentSize);
    if (!nextDateRaw) {
      return '';
    }

    //format nextDate as Nov 25
    let month = nextDateRaw.toLocaleString('default', { month: 'short' }),
      day = nextDateRaw.getDate(),
      year = nextDateRaw.getFullYear();

    return `${month} ${day}`;
  };

  const getNextDateRaw = (nextSize, currentSize) => {
    let nextDate = '';

    if (!nextSize || nextSize.indexOf('-') === -1) {
      let lastNumber = currentSize.split('-')[1],
        birthDate = new Date(birthday),
        curDate = new Date();
      nextDate = new Date(birthDate.setMonth(birthDate.getMonth() + parseInt(lastNumber)));

      if (nextDate < curDate) {
        return '';
      }
    } else {
      let firstNumber = nextSize.split('-')[0],
        birthDate = new Date(birthday),
        curDate = new Date();
      nextDate = new Date(birthDate.setMonth(birthDate.getMonth() + parseInt(firstNumber)));

      if (nextDate < curDate) {
        return '';
      }
    }

    return nextDate;
  };

  return (
    <>
      {Object.keys(results).length ? (
        <Results
          data={results}
          onBackClick={onBackClick}
          birthday={birthday}
          measureDate={measureDate}
          measurementId={measurementId}
        />
      ) : (
        <>
          {isLoading ? (
            <MainFormLoading />
          ) : (
            <div className="main-form">
              <div className="form-header">
                <a href={'/get'} onClick={onBackClick} className="btn btn-sm btn-back">
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512">
                    <path d="M17.17,24a1,1,0,0,1-.71-.29L8.29,15.54a5,5,0,0,1,0-7.08L16.46.29a1,1,0,1,1,1.42,1.42L9.71,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,17.17,24Z" />
                  </svg>
                </a>

                <img src={logo} alt="Logo" />
              </div>

              <div className="main-form-content">
                <div className="main-form-description">
                  AI generated baby growth forecasting tool to make shopping and planning simple.
                </div>

                <h2 className="main-form-subheader">Tell us about your child</h2>

                <div className="form-group">
                  <label className="form-label">Gender</label>
                  <div className="double-field">
                    <div
                      className={'form-control short-control' + (gender === 'male' ? ' active' : '')}
                      onClick={() => {
                        setGender('male');
                      }}
                    >
                      <img src={imgGenderMale} alt="Male gender" /> Boy
                    </div>
                    <div
                      className={'form-control short-control' + (gender === 'female' ? ' active' : '')}
                      onClick={() => {
                        setGender('female');
                      }}
                    >
                      <img src={imgGenderFemale} alt="Female gender" /> Girl
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Birthday</label>
                  <input
                    type="date"
                    className={'form-control birthday-date-input' + (errors.birthday ? ' has-error' : '')}
                    placeholder="Child birth date..."
                    value={birthday}
                    onChange={onBirthdayChange}
                  />
                  {errors.birthday && <div className="form-error">{errors.birthday}</div>}
                </div>

                <div className="double-field">
                  <div className="form-group short-group field-dimension field-height">
                    <label className="form-label">Height</label>
                    <input
                      type="text"
                      pattern="[0-9\.,]*"
                      inputMode="decimal"
                      className={'form-control decimal-input' + (errors.height ? ' has-error' : '')}
                      value={height}
                      onChange={onHeightChange}
                    />
                    {errors.height && <div className="form-error">{errors.height}</div>}
                  </div>

                  <div className="form-group short-group field-dimension field-weight">
                    <label className="form-label">Weight</label>
                    <input
                      type="text"
                      pattern="[0-9\.,]*"
                      inputMode="decimal"
                      className={'form-control decimal-input' + (errors.weight ? ' has-error' : '')}
                      value={weight}
                      onChange={onWeightChange}
                    />
                    {errors.weight && <div className="form-error">{errors.weight}</div>}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Measure date</label>
                  <input
                    type="date"
                    className={'form-control measure-date-input' + (errors.measure ? ' has-error' : '')}
                    value={measureDate}
                    onChange={onMeasureDateChange}
                  />
                  {errors.measure && <div className="form-error">{errors.measure}</div>}
                </div>

                <div className="form-group">
                  <button className="btn btn-secondary fs-small" onClick={onSubmit}>
                    Grow with me!
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
