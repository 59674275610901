import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Home from './components/common/Home';
import React from 'react';
import Error404 from './components/errors/Error404';
import MainForm from './components/common/MainForm';
import Results from './components/common/Results';
import Feedback from './components/common/Feedback';
import FeedbackSent from './components/common/FeedbackSent';

export const router = createBrowserRouter([
  {
    path: '/',
    Component: App,
    children: [
      {
        index: true,
        //loader: homeLoader,
        element: <Home />,
      },
      {
        path: 'get',
        element: <MainForm />,
      },
      {
        path: 'results',
        element: <MainForm />,
      },
      {
        path: 'feedback-sent',
        element: <FeedbackSent />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
      },
    ],
  },
  {
    path: '*',
    Component: Error404,
  },
]);
