import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/base/theme.css";
import "./assets/css/styles/merged.css";


import reportWebVitals from './reportWebVitals';


import {RouterProvider} from "react-router-dom";
import {AuthProvider} from "./providers/AuthProvider";
import {ApiProvider} from "./providers/ApiProvider";
import {NotificationsProvider} from "./providers/NotificationsProvider";
import {router} from "./router";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <ApiProvider>
            <NotificationsProvider>
                <RouterProvider router={router}/>
            </NotificationsProvider>
        </ApiProvider>
    </AuthProvider>
);

reportWebVitals();