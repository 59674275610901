import React, { useEffect } from 'react';

import imgObjects from '../../assets/img/objects.svg';
import { Link } from 'react-router-dom';

export default function FeedbackSent(props) {
  useEffect(() => {
    document.title = 'Feedback sent | Grow with me!';
  }, []);

  return (
    <div className="results-block">
      <div className="form-header ">
        <Link to={'/'} className="btn btn-sm btn-back btn-light-to-blue">
          <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512">
            <path d="M17.17,24a1,1,0,0,1-.71-.29L8.29,15.54a5,5,0,0,1,0-7.08L16.46.29a1,1,0,1,1,1.42,1.42L9.71,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,17.17,24Z" />
          </svg>
        </Link>
      </div>

      <div className="feedback-content">
        <div className="feedback-description">
          <img src={imgObjects} className={'mb-4 w-75'} alt="Likes image" />
          <p style={{ fontWeight: 600, fontSize: '36px' }}>Thank you!</p>
          <Link to={'/results'} className="btn" style={{ width: '311px', border: '1px solid #DEDEDE' }}>
            Show results again
          </Link>
          <Link to={'/get'} className="btn btn-secondary">
            Make another prediction
          </Link>
        </div>
      </div>
    </div>
  );
}
