import React, {useEffect} from 'react';


import imgLikes from "../../assets/img/likes.svg";
import {Link, useNavigate} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import InfoModel from "../../models/InfoModel";


export default function Feedback(props) {
    const apiContext = useApi();
    const infoModel = new InfoModel(apiContext.api);
    const navigate = useNavigate();

    const [feedbackText, setFeedbackText] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [measurementId, setMeasurementId] = React.useState(null);


    useEffect(() => {
        document.title = 'Feedback | Grow with me!';

        setMeasurementId(sessionStorage.getItem('measurementId'));
    }, []);


    const validateFeedbackText = (value) => {
        let errorsList = [];
        if (!value) {
            errorsList.push('Feedback text is required.');
        }

        if (value.length < 3) {
            errorsList.push('Feedback text is too short.');
        }

        return errorsList;
    }
    const onFeedbackTextChange = (e) => {
        let value = e.target.value;
        let curErrors = validateFeedbackText(value);
        if (curErrors.length) {
            setErrors({
                ...errors,
                feedbackText: curErrors.join(' ')
            });
        } else {
            setErrors({
                ...errors,
                feedbackText: '',
            });
        }

        setFeedbackText(value);
    }


    const validateEmail = (value) => {
        let errorsList = [];
        if (!value) {
            errorsList.push('Email is required.');
        }

        //check if email is valid
        let lastAtPos = value.lastIndexOf('@');
        let lastDotPos = value.lastIndexOf('.');
        if (!(lastAtPos !== -1 && lastDotPos !== -1 && (lastAtPos < lastDotPos) && (lastDotPos > 2))) {
            errorsList.push('Email is invalid.');
        }

        return errorsList;
    }
    const onEmailChange = (e) => {
        let value = e.target.value;
        let curErrors = validateEmail(value);
        if (curErrors.length) {
            setErrors({
                ...errors,
                email: curErrors.join(' ')
            });
        } else {
            setErrors({
                ...errors,
                email: '',
            });
        }

        setEmail(value);
    }


    const onSubmit = (e) => {
        e.preventDefault();

        let errorsFeedbackText = validateFeedbackText(feedbackText);
        let errorsEmail = validateEmail(email);

        if (errorsFeedbackText.length || errorsEmail.length) {
            setErrors({
                feedbackText: errorsFeedbackText.join(' '),
                email: errorsEmail.join(' ')
            });
            return;
        } else {
            setErrors({});
        }

        infoModel.feedback({
            id: measurementId,
            feedback: feedbackText,
            email: email,
        }).finally(() => {
            navigate('/feedback-sent');
        });
    }


    return (
        <div className="results-block">
            <div className="form-header ">
                <Link to={'/'} className="btn btn-sm btn-back btn-light-to-blue">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M17.17,24a1,1,0,0,1-.71-.29L8.29,15.54a5,5,0,0,1,0-7.08L16.46.29a1,1,0,1,1,1.42,1.42L9.71,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,17.17,24Z"/></svg>
                </Link>
            </div>

            <div className="feedback-content">
                <div className="feedback-description">
                    <img src={imgLikes} alt="Likes image"/>
                    <p>Tell us more about your experience!</p>
                </div>
                <div className="feedback-form">
                    <div className="form-group">
                        <textarea className={'form-control'} rows={6} placeholder={'Your feedback'} value={feedbackText} onChange={onFeedbackTextChange}></textarea>
                        {errors.feedbackText && (
                            <div className="form-error">{errors.feedbackText}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <input type={'email'} className={'form-control'} placeholder={'Your email'} value={email} onChange={onEmailChange} />
                        {errors.email && (
                            <div className="form-error">{errors.email}</div>
                        )}
                    </div>

                    <button className={'btn btn-block btn-secondary'} onClick={onSubmit}>Send feedback</button>
                </div>
            </div>
        </div>
    );
}