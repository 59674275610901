/**
 * Info model
 *
 * @param {ApiHelper} api
 */
export default class InfoModel {
    constructor(api) {
        this.api = api;
    }


    async calculate(data, errorHandler) {
        const url = '/get';

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async saveResults(data, errorHandler) {
        const url = '/save';

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async feedback(data, errorHandler) {
        const url = '/feedback';

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }


    async vote(data, errorHandler) {
        const url = '/vote';

        const response = await this.api.postRequest(url, data);

        if (response.success === false) {
            if (errorHandler) {
                errorHandler(response);
            }
            return false;
        }

        return (response.success === true)?(response.data):false;
    }
}