import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

const App = props => {
  const location = useLocation();
  const [preloaderActive, setPreloaderActive] = React.useState(true);
  const [isWhiteBackground, setIsWhiteBackground] = React.useState(false);

  const checkBackground = () => {
    let whiteBackground = false;
    if (window.location.pathname.includes('/results') || window.location.pathname.includes('/feedback')) {
      whiteBackground = true;
    }

    setIsWhiteBackground(whiteBackground);
  };

  useEffect(() => {
    setPreloaderActive(false);

    checkBackground();
  }, []);

  useEffect(() => {
    checkBackground();
  }, [location]);

  return (
    <>
      <div className={'preloader' + (!preloaderActive ? ' hide' : '')}></div>
      <div className={'main-container' + (isWhiteBackground ? ' bg-white' : '')}>
        <Outlet />
      </div>
      <ToastContainer />
    </>
  );
};

export default App;
