import React, { useEffect } from 'react';

import imgArrow from '../../assets/img/arrow.svg';
import imgThumbsUp from '../../assets/img/like.svg';
import imgThumbsDown from '../../assets/img/dislike.svg';
import imgGap from '../../assets/img/brands/gap.jpg';
import imgHm from '../../assets/img/brands/hm.jpg';
import imgCarters from '../../assets/img/brands/carters.jpg';
import imgRL from '../../assets/img/brands/ralphlauren.jpg';
import imgZara from '../../assets/img/brands/zara.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../../providers/ApiProvider';
import InfoModel from '../../models/InfoModel';
import imgClose from '../../assets/img/close.svg';

export default function Results(props) {
  const navigate = useNavigate();
  const apiContext = useApi();
  const infoModel = new InfoModel(apiContext.api);
  const [feedbackActivated, setFeedbackActivated] = React.useState(false);
  const [feedbackBack, setFeedbackBack] = React.useState(false);
  const [feedbackText, setFeedbackText] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [likeActive, setLikeActive] = React.useState(false);
  const [disLikeActive, setDisLikeActive] = React.useState(false);

  useEffect(() => {
    document.title = 'Results | Grow with me!';
    const feedbackSentStatus = window.localStorage.getItem('feedBackStatus');

    if (!feedbackSentStatus) {
      setTimeout(() => {
        setFeedbackBack(true);
      }, 4800);

      setTimeout(() => {
        setFeedbackActivated(true);
      }, 5000);
    }
  }, []);

  const validateFeedbackText = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Feedback text is required.');
    }

    if (value.length < 3) {
      errorsList.push('Feedback text is too short.');
    }

    return errorsList;
  };

  const onFeedbackTextChange = e => {
    let value = e.target.value;
    let curErrors = validateFeedbackText(value);
    if (curErrors.length) {
      setErrors({
        ...errors,
        feedbackText: curErrors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        feedbackText: '',
      });
    }

    setFeedbackText(value);
  };

  const validateEmail = value => {
    let errorsList = [];
    if (!value) {
      errorsList.push('Email is required.');
    }

    //check if email is valid
    let lastAtPos = value.lastIndexOf('@');
    let lastDotPos = value.lastIndexOf('.');
    if (!(lastAtPos !== -1 && lastDotPos !== -1 && lastAtPos < lastDotPos && lastDotPos > 2)) {
      errorsList.push('Email is invalid.');
    }

    return errorsList;
  };

  const onEmailChange = e => {
    let value = e.target.value;
    let curErrors = validateEmail(value);
    if (curErrors.length) {
      setErrors({
        ...errors,
        email: curErrors.join(' '),
      });
    } else {
      setErrors({
        ...errors,
        email: '',
      });
    }

    setEmail(value);
  };

  const onSubmit = e => {
    e.preventDefault();

    let errorsFeedbackText = validateFeedbackText(feedbackText);
    let errorsEmail = validateEmail(email);

    if (errorsFeedbackText.length || errorsEmail.length) {
      setErrors({
        feedbackText: errorsFeedbackText.join(' '),
        email: errorsEmail.join(' '),
      });
      return;
    } else {
      setErrors({});
    }

    infoModel
      .feedback({
        id: props.measurementId,
        feedback: feedbackText,
        email: email,
      })
      .finally(() => {
        window.localStorage.setItem('feedBackStatus', '1');
        navigate('/feedback-sent');
      });
  };

  const onFeedbackClick = type => {
    infoModel.vote({
      id: props.measurementId,
      type: type,
    });
  };

  return (
    <div className="results-block">
      <div className="form-header ">
        <a href={'/get'} onClick={props.onBackClick} className="btn btn-sm btn-back btn-light-to-blue">
          <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512">
            <path d="M17.17,24a1,1,0,0,1-.71-.29L8.29,15.54a5,5,0,0,1,0-7.08L16.46.29a1,1,0,1,1,1.42,1.42L9.71,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,17.17,24Z" />
          </svg>
        </a>

        <h1>Results</h1>
      </div>

      <div className="results-block-content">
        <div className="results-block-description">
          Size upgrades and dates
          <span className="subtitle">Sizes in months</span>
        </div>

        <div className="results-block-sizes">
          <div className="results-block-sizes-header">
            Next
            <span>
              <span>Today</span>
              <img src={imgArrow} alt="Arrow right" />
            </span>
          </div>
          <div className="results-block-sizes-content">
            {props.data &&
              Object.keys(props.data).map((brand, index) => {
                let brandImg = '';
                switch (brand) {
                  case 'Gap':
                    brandImg = imgGap;
                    break;
                  case 'H&M':
                    brandImg = imgHm;
                    break;
                  case "Carter's":
                    brandImg = imgCarters;
                    break;
                  case 'Ralph Lauren':
                    brandImg = imgRL;
                    break;
                  case 'Zara':
                    brandImg = imgZara;
                    break;
                  default:
                    brandImg = imgGap;
                    break;
                }
                return (
                  <div className={'brand-row'} key={index}>
                    <span className={'size-img'}>
                      <img src={brandImg} alt={brand} />
                    </span>
                    <span className={'size-value-today'}>{props.data[brand].current}</span>
                    <span className={'size-value-next'}>{props.data[brand].next ? props.data[brand].next : '—'}</span>
                    <span className={'size-date-next'}>
                      {props.data[brand].date} <span>{props.data[brand].day}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className={`results-block-feedback ${feedbackBack ? 'open' : ''}`}>
        <div className={`feedback-content ${feedbackActivated ? 'open' : ''}`}>
          <div className="results-block-feedback-header">Helpful?</div>
          <div
            style={{ cursor: 'pointer', position: 'absolute', right: '12px' }}
            onClick={() => {
              setFeedbackActivated(false);
              setFeedbackBack(false);
            }}
          >
            <img src={imgClose} alt="" width={20} />
          </div>
          <div className="results-block-feedback-btns">
            <button
              className={`btn btn-light ${likeActive ? 'btn-custom-active' : ''}`}
              onClick={() => {
                setLikeActive(true);
                setDisLikeActive(false);
                onFeedbackClick('up');
              }}
            >
              <img src={imgThumbsUp} alt="Thumbs Up" />
            </button>
            <button
              className={`btn btn-light ${disLikeActive ? 'btn-custom-active' : ''}`}
              onClick={() => {
                setLikeActive(false);
                setDisLikeActive(true);
                onFeedbackClick('down');
              }}
            >
              <img src={imgThumbsDown} alt="Thumbs Down" />
            </button>
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <textarea
              className={'form-control'}
              rows={3}
              placeholder={'Your feedback'}
              value={feedbackText}
              onChange={onFeedbackTextChange}
            ></textarea>
            {errors.feedbackText && <div className="form-error">{errors.feedbackText}</div>}
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <input
              type={'email'}
              className={'form-control'}
              placeholder={'Your email'}
              value={email}
              onChange={onEmailChange}
            />
            {errors.email && <div className="form-error">{errors.email}</div>}
          </div>
          <button
            className="btn"
            onClick={onSubmit}
            style={{
              width: '100%',
              height: '40px',
              borderRadius: '12px',
              backgroundColor: '#3c496a',
              color: '#dde4f2',
              fontSize: '16px',
            }}
          >
            Send feedback
          </button>
        </div>
      </div>
    </div>
  );
}
