import React, {useEffect} from 'react';

import imgObjects from "../../assets/img/objects.svg";
import imgLoading from "../../assets/img/loading.svg";


export default function MainFormLoading(props) {


    useEffect(() => {
    }, []);


    return (
        <div className="main-form-loading">
            <img src={imgObjects} alt="Loading illustration" className="main-form-loading-objects" />

            <div className="main-form-loading-block">
                <div className="main-form-loading-section">
                    Creating sizing calendar...
                </div>
                <div className="home-secondary-block-section">
                    <img src={imgLoading} alt="Loading animation" className="main-form-loading-animation spin-image" />
                </div>
            </div>
        </div>
    );
}