import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.svg';
import imgHomeHero from '../../assets/img/home-img-promo.png';
import { useApi } from '../../providers/ApiProvider';

function Home(props) {
  const apiContext = useApi();
  const [dataItems, setDataItems] = React.useState({});

  useEffect(() => {
    document.title = 'Grow with me!';
    window.localStorage.clear();
  }, []);

  return (
    <div className="home-page">
      <div className="home-header">
        <img src={logo} alt="Logo" />

        <Link to={'/get'} className="btn btn-sm btn-light">
          Get Started
        </Link>
      </div>

      <div className="home-entry-text">
        AI generated baby growth forecasting tool to make shopping and planning simple!
      </div>

      <div className="home-secondary-block">
        <div className="home-secondary-block-section">
          <img src={imgHomeHero} />
        </div>
        <div className="home-secondary-block-section">
          Grow, inspired by our 20-year friendship and motherhood experiences, was created by Victoria, mom of two boys,
          and Marjia, mom of two girls. Balancing work and parenting, we faced challenges with our kids' changing sizes.
          Grow is an app that predicts your child's next clothing size, saving time and money, and is great for parents
          of preemies. From our family to yours, enjoy Grow and happy growing!
        </div>
        <div className="home-secondary-block-section">
          <Link to={'/get'} className="btn btn-light btn-light-to-blue">
            Grow with me!
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
